import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabsSection from '../../components/Tabs/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    card: {
        background: '#fff',
        borderRadius: 6,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(8)
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: theme.spacing(4, 3, 4, 4)
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3)
        }
    },
    avatar: {
        width: 50,
        height: 50,
        border: '2px solid #0850DB'
    },
    name: {
        fontFamily: 'Inter',
        color: '#31517A',
        fontSize: '18px',
        lineHeight: '30px',
        fontWeight: 600,
        textAlign: 'left'
    },
    via: {
        fontSize: '14px',
        lineHeight: '30px',
        color: '#626680',
        textAlign: 'left'
    },
    source: {
        maxWidth: '45px',
        maxHeight: '45px',
    },
    flexGrowBox: {
        paddingLeft: theme.spacing(2)
    },
    rating: {
        float: 'left'
    },
    text: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 2, 3)
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0)
        }
    },
    iconEmpty: {
        color: '#C4C4C4'
    },
    iconFilled: {
        color: 'rgba(17, 96, 247, 0.52)'
    }
}));

const SOURCES = [
    {
        'name': 'Google Play'
    },
    {
        'name': 'Google'
    },
    {
        'name': 'Apple Store'
    }
];

const UserReviewsTabs = ({ reviews }) => {
    const classes = useStyles();
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const content = [];
        if (reviews.length > 0) {
            reviews.forEach((card, index) => {
                content.push(renderCard(card));

                if (index === reviews.length - 1) setCards(content);
            });
        }
    }, []);

    const renderCard = card => {
        const { name, rating, photo, source, text } = card;
        const via = SOURCES[parseInt(source)-1].name;

        return (
            <Grid container justify='center'>
                <Grid item xs={12} md={10}>
                    <Box className={classes.card}>
                        <Box display='flex' mb={3}>
                            <Box display='flex' alignItems='center'>
                                {photo && <Avatar src={photo} className={classes.avatar} alt={`${name}'s photo`}/>}
                            </Box>
                            <Box flexGrow={1} className={classes.flexGrowBox}>
                                <Typography className={classes.name}>{name}</Typography>
                                <Rating
                                    readOnly
                                    value={parseFloat(rating)}
                                    classes={{ root: classes.rating, iconEmpty: classes.iconEmpty, iconFilled: classes.iconFilled }}
                                    />
                                    {source && <Typography className={classes.via}>{`via ${via}`}
                                </Typography>}
                            </Box>
                            {source && <Box>
                                <Box>
                                    <img src={`/new/sources/${source}.svg`} alt={via} className={classes.source} />
                                </Box>
                            </Box>}
                        </Box>
                        <Typography className={classes.text} align='left'>
                            <Markdown children={text} />
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    return (
        <TabsSection slider data={cards} variant='secondary' />
    )
};

UserReviewsTabs.propTypes = {
    reviews: PropTypes.array
};

export default UserReviewsTabs;
