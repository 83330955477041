import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import TabsSection from '../../components/Tabs/Tabs';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles(theme => ({
  box: {
    background: "#fff",
    borderRadius: "6px",
    border: '1px solid #CACCD5',
    padding: theme.spacing(3),
    height: '100%'
  },
  content: {
    fontFamily: 'Inter',
    textAlign: 'left',
    lineHeight: '35px',
    color: '#626680',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    marginTop: theme.spacing(2)
  },
  quote: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  name: {
    fontWeight: 500,
    color: '#626680',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    lineHeight: '35px',
    fontFamily: 'Inter',
    textAlign: 'left',
    paddingTop: theme.spacing(2)
  },
  source: {
    maxWidth: '45px',
    maxHeight: '45px',
    marginTop: theme.spacing(1.5)
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Inter',
    color: '#31517A',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      lineHeight: '52px',
    },
    [theme.breakpoints.between('sm, md')]: {
      fontSize: '32px',
      lineHeight: '48px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      lineHeight: '39px',
    }
  },
  text: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Inter',
    color: '#626680',
    fontWeight: 500,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '48px',
    },
    [theme.breakpoints.between('sm, md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    }
  }
}));

const renderTitle = (title, text, classes) => {
  return (
    <>
      {title && <Typography className={classes.title}>{title}</Typography>}
      {text && <Typography className={classes.text}>{text}</Typography>}
    </>
  )
}

const renderReviewsBlock = (reviews, classes) => {
  return (
    <Grid className={classes.container} container justify='center' spacing={4}>
      {reviews && reviews.map((item, index) => (
        <Grid item xs={12} md={4} sm={6} key={index}>
          <div className={classes.box}>
            <Box className={classes.quote}>
              <img src="/new/quote-user.svg" alt="Quote" />
            </Box>
            <Typography className={classes.content}>
              <Markdown children={item.text} />
            </Typography>
            <Box display='flex'>
              <Box flexGrow={1}>
                <Typography className={classes.name}>{item.name}</Typography>
              </Box>
              {item.source && (
                <Box>
                  <img src={`/new/sources/${item.source}.svg`} alt={item.source} className={classes.source} />
                </Box>
              )}
            </Box>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

const UserReviews = ({ reviews, title, text }) => {
  const classes = useStyles();
  const [sliced, setSliced] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const REVIEW_PER_SLIDE = isTablet ? 2 : isMobile ? 1 : 3;

  useEffect(() => {
    if (reviews.length > 3) {
      const array = [];
      for (var i = 0; i < reviews.length; i += REVIEW_PER_SLIDE)
        array.push(renderReviewsBlock(reviews.slice(i, i + REVIEW_PER_SLIDE), classes));

      setSliced(array);
    }
  }, [REVIEW_PER_SLIDE])

  if (sliced.length === 0) {
    return (
      <>
        {renderTitle(title, text, classes)}
        {renderReviewsBlock(reviews, classes)}
      </>
    )
  } else if (sliced.length > 0) {
    return (
      <>
        {renderTitle(title, text, classes)}
        <TabsSection slider data={sliced} />
      </>
    )
  }
  else return null;
};

UserReviews.propTypes = {
  reviews: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string
}

export default UserReviews;
