import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import { Grid, Box, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    box: {
        background: "#fff",
        borderRadius: "6px",
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
        display: 'flex',
        flexWrap: 'wrap'
    },
    boxName: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        fontFamily: 'Inter',
        textAlign: 'left',
        color: '#626680',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            lineHeight: '26px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '20px',
        },
        marginTop: theme.spacing(4)
    },
    container: {
        marginTop: theme.spacing(5)
    },
    quote: {
        display: 'flex',
        alignItems: 'left'
    },
    name: {
        fontWeight: 500,
        color: '#626680',
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        lineHeight: '28px',
        fontFamily: 'Inter',
        textAlign: 'left',
        paddingTop: theme.spacing(4)
    },
    avatar: {
        width: 60,
        height: 60,
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2)
    },
}));

const UserReviewsCards = ({ reviews }) => {
    const classes = useStyles();

    return (
        <Grid className={classes.container} container justify='center' spacing={3}>
            {reviews && reviews.map((item, index) => (
                <Grid item xs={12} md={4} sm={6} key={index}>
                    <div className={classes.box}>
                        <Box>
                            <Box className={classes.quote}>
                                <img src="/new/user-quote-v2.svg" alt="Quote" />
                            </Box>
                            <Typography className={classes.content} component='div'>
                                <Markdown children={item.text} />
                            </Typography>
                        </Box>
                        <Box className={classes.boxName} alignSelf="flex-end">
                            <Box>
                                {item.photo && <Avatar src={item.photo} className={classes.avatar} alt={`${item.name}'s photo`}/>}
                            </Box>
                            <Box flexGrow={1}>
                                <Typography className={classes.name}>{item.name}</Typography>
                                <Box display='flex' justifyContent='left'>
                                    {item.rating && <Rating value={item.rating} precision={0.1} readOnly size='small' />}
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}

UserReviewsCards.defaultProps = {
    reviews: []
}

UserReviewsCards.propTypes = {
    reviews: PropTypes.array
};

export default UserReviewsCards;
