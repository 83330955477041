import React, { useRef } from 'react';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './TabsSection.module.scss';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../remitbee/theme/Theme';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import { useRouter } from 'next/router';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Markdown from 'markdown-to-jsx';

interface Props {
    reverse?: boolean;
    reverseMobile?: boolean;
    backgroundColor?: string;
    promotion?: any;
    listItems: any;
    title?: string;
}

const TabsSectionDS:React.FC<Props> = ({
    reverse,
    reverseMobile,
    backgroundColor,
    promotion,
    listItems,
    title,
}) => {
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const router = useRouter();
    const list = listItems ? typeof listItems==='string' ? parseArrFromString(listItems) : listItems : null;


    const scrollRef = useRef(null);

    const setSelectedTabAndScroll = (index) => {
        setSelectedTab(index);
        if (scrollRef.current) {
            const containerWidth = scrollRef?.current?.clientWidth;
            const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
            const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
            const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
            scrollRef?.current?.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    const goToUrl = async (actionUrl) => {
        if (!actionUrl) return;
        if (actionUrl.startsWith('http')) {
            window.open(actionUrl, '_blank');
        } else if (actionUrl.startsWith('www')) {
            window.open(`https://${actionUrl}`, '_blank');
        } else {
            let query = null;
            if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath)
            if (actionUrl && actionUrl.includes('signup?')) {
                router.push(actionUrl);
                return
            }
            if (promotion) query = addReferralLink()
            router.push({ pathname: actionUrl, query });
        }
    };

    return (
        <section style={{ backgroundColor: backgroundColor || null}}>
        <Box className={classes['rb-root']}>
        <Box
            display='flex' 
            flexDirection={!isNotTablet ? reverseMobile ? 'column-reverse' : 'column' : reverse ? 'row-reverse' : 'row'}
            justifyContent='center'
            alignItems={title ? 'center' :'flex-start'}
            gap={!isNotTablet ? 40 : 64}
        >
            
            <Box display='flex' justifyContent={!isNotTablet ? 'center' : reverse ? 'flex-start' : 'flex-end'} alignItems='center' className={classes['rb-image-box']}>
                {list?.length > 0 && list[selectedTab] && <img src={list[selectedTab]?.imageUrl} alt={getImageAlt(list[selectedTab]?.imageUrl)} className={classes['rb-image']} />}
            </Box>
            <Box>
           {
            title && <>
            <div className={isMobile ? classes['rb-title'] : null}>
           <Typography variant='displayLg' weight='bold' color={theme.palette.primary.navy}>
                    {title}
                </Typography>
                <Spacing variant='betweenSections' />
           </div>
            </>
           }
            <Box componentRef={scrollRef} className={classes['rb-tab-title-box']}>
                {list?.map((item, index) => { return (
                    <Box 
                    className={`
                        ${classes['rb-tab-title']}
                        ${index === selectedTab && classes['rb-tab-title-active']}
                    `}
                    onClick={()=>setSelectedTabAndScroll(index)}
                    >
                        <Typography variant='textMd' weight='semibold' className={classes['rb-tab']} color={index === selectedTab && theme.palette.background.white}>
                            <Markdown children={item.tabHeader as React.ReactNode} />
                        </Typography>
                    </Box>)
                })}
            </Box>
            {list?.length > 0 && list[selectedTab] && 
            <Box className={classes['rb-content-box']}>
                <Spacing variant='betweenSections' />
                <Box display='flex' justifyContent='flex-start' flexDirection='column' gap={16}>
                    <Box display='flex' flexDirection='column' gap={8}>
                        <Typography variant='textMd' weight='bold' color={theme.palette.primary.blue}>
                            <Markdown children={list[selectedTab]?.tag} />
                        </Typography>
                        <Typography variant='displayLg' weight='bold' color={theme.palette.primary.navy}>
                            <Markdown children={list[selectedTab]?.title} />
                        </Typography>
                    </Box>
                    <Typography paragraph={true} variant='textMd' style={{ display: 'inline'}}>
                        <Markdown children={list[selectedTab]?.text} style={{ display: 'inline'}} />
                    </Typography>
                </Box>
                <Spacing variant='betweenSections' />
               {list[selectedTab]?.actionText && <Box display='flex' justifyContent='flex-start' flexDirection='column' gap={16}>
                    <Button id={`Tab-${selectedTab}`} fullWidth={!isNotTablet} variant={list[selectedTab]?.actionVariant || 'outlined'} onClick={()=>goToUrl(list[selectedTab]?.actionUrl)} className={classes['rb-button']}>
                            {list[selectedTab]?.actionText}
                    </Button>
                </Box>}
            </Box>}
            </Box>
        </Box>
        <Spacing variant='fromHeaderToContentInSection' />
        </Box>
        </section>
    );
};

export default TabsSectionDS;