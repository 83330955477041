import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    quote: {
        display: 'flex',
        justifyContent: 'left',
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(3),
        }
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    iframe: {
        width: '400px',
        height: '400px',
        marginTop: theme.spacing(5),
        borderRadius: '400px',
        borderStyle: 'solid',
        borderWidth: '0px',
        [theme.breakpoints.down('md')]: {
          width: '400px',
          marginRight: 'auto',
          marginLeft: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
          width: '327px',
          height: '327px',
          borderRadius: '327px',
          marginRight: 'auto',
          marginLeft: 'auto',
        },
      },
    iframeDiv: {
        // minWidth: '100%',
        display: 'flex',
        width: '1000px',
        [theme.breakpoints.down('sm')]: {
          width: '700px'
        },
      },
      videoZoom: {
        maxWidth: '100%',
        width: 'auto',
        marginTop: theme.spacing(5)
      },
      video: {
        width: '400px !important',
        height: '400px !important',
        marginTop: theme.spacing(5),
        borderRadius: '400px !important',
        borderStyle: 'solid',
        borderWidth: '0px',
      },
      videoDiv: {
        minWidth: '100%',
      },
    quoteTitle: {
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '38px',
        color: '#31517A',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            fontSize: '28px',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
        }
    },
    quoteText: {
        fontFamily: 'Inter',
        lineHeight: '34px',
        color: '#626680',
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
            fontSize: '18px',
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            marginTop: theme.spacing(1),
        }
    },
    name: {
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#31517A',
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
            fontSize: '20px',
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            marginTop: theme.spacing(3),
        }
    },
    rating: {
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
        }
    }
}));

const UserSingleReview = (props) => {
    const { image, name, rating, text, title, reverse,video, showVideo, videoProps, zoom,videoRef } = props || {};
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const hideRating = !rating && rating === 0

    return (
        <Grid container direction="row" alignItems="center" spacing={5}>
            {!isMobile && !reverse && <Grid item xs={12} md={6}>
                {image && (
                    <Box display='flex' justifyContent='center'>
                        <img src={image} alt={name} className={classes.image} />
                    </Box>
                )}
                  {video && showVideo &&
            <>
                {video.includes('youtube.com/embed/') ? 
                 <iframe {...videoProps} src={video} loading="eager" className={classes.iframe} allowFullScreen/>
                :
                <video muted {...videoProps} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" controlsList='nodownload' className={zoom ? classes.videoZoom : classes.video} controls ref={videoRef} />
                } 
            </>
          }
            </Grid>}
            <Grid item xs={12} md={6}>
                <Box className={classes.quote}>
                    <img src="/new/user-quote-big.svg" alt="Quote" />
                </Box>
                {title && <Typography className={classes.quoteTitle}>{title}</Typography>}
                {text && <Typography className={classes.quoteText}>{text}</Typography>}
                {name && <Typography className={classes.name}>{name}</Typography>}
                {!hideRating && <Box display='flex' justifyContent='left' className={classes.rating}>
                    <Rating value={rating} precision={0.1} readOnly size='small' />
                </Box>}
            </Grid>
            {isMobile && <Grid item xs={12} md={6}>
                {image && (
                    <img src={image} alt={name} className={classes.image} />
                )}
                  {video && showVideo &&
            <>
                {video.includes('youtube.com/embed/') ? 
                 <iframe {...videoProps} src={video} loading="eager" className={classes.iframe} allowFullScreen/>
                :
                <video muted {...videoProps} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" controlsList='nodownload' className={zoom ? classes.videoZoom : classes.video} controls ref={videoRef} />
                } 
            </>
          }
            </Grid>}
            {!isMobile && reverse && <Grid item xs={12} md={6}>
                {image && (
                    <Box display='flex' justifyContent='center'>
                        <img src={image} alt={name} className={classes.image} />
                    </Box>
                )}
                 {video && showVideo &&
            <>
                {video.includes('youtube.com/embed/') ? 
                 <iframe {...videoProps} src={video} loading="eager" className={classes.iframe} allowFullScreen/>
                :
                <video muted {...videoProps} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" controlsList='nodownload' className={zoom ? classes.videoZoom : classes.video} controls ref={videoRef} />
                } 
            </>
          }
            </Grid>}
        </Grid>
    )
}

UserSingleReview.defaultProps = {
    image: '',
    name: '',
    rating: 0,
    text: '',
    title: ''
}

UserSingleReview.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    rating: PropTypes.number,
    text: PropTypes.string,
    title: PropTypes.string
};

export default UserSingleReview;
